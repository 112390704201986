<template>
  <div>
    <div class="container clearfix">
      <div class="row sub_header" style="--bs-gutter-x:0px;--bs-gutter-y:0px;">
      <div class="col">
        <a v-on:click.prevent="back()" href=""><i class="fas fa-arrow-left"></i> Назад</a>
      </div>
      <div class="col text-end" style="padding-right:10px;">
        {{data.title}}
      </div>
      
    </div>
    </div>
    <div class="container-fluid" style="padding-top:50px;">

      <div>
        <b>Описание</b>: <br /> {{data.description}}
      </div>

      <div>
        <b>Локация</b>: <br /> {{data.location}}
      </div>

      <div>
        <b>Време</b>: <br /> {{data.time}}
      </div>
      
    </div>
  </div>
</template>

<style scoped lang="less">
  .sub_header {
    border-bottom: 1px solid #dbdbdb;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #f4f4f4;
    line-height: 40px;

    a {
      color: #4a4a4a;
      padding-left:10px;
      text-decoration:none;
      display:block;
    }

  }
</style>

<script>
  import API from '@/helpers/api'
  export default {
    methods: {
    back () {
      try {
        window.ReactNativeWebView.postMessage("Календар");
      } catch(er) {
         // console.log(er)
      }
      this.$router.replace({name:"Home"})
    },
      loaded: function () {
       API.getEvent(this.id).then(function(r){
          this.data = r;

          try {
            window.ReactNativeWebView.postMessage(r.title);
          } catch(er){
            // console.log(er)
          }
          
        }.bind(this))
      

      },
    },
    created() {
      this.loaded()
    },
    props: ["id"],
    name: "EventInfo",
    data() {
      return {
        title: "",
        data: {
          title: "",
          location: "",
          description: "",
          time: "",
          delonum: "",
        }
      }
    }
  };
</script>