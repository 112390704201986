/**
 * @fileoverview gRPC-Web generated client stub for grpc_service
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.grpc_service = require('./app_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc_service.InternalServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc_service.InternalServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc_service.LoginRequest,
 *   !proto.grpc_service.LoginResponse>}
 */
const methodDescriptor_InternalService_Login = new grpc.web.MethodDescriptor(
  '/grpc_service.InternalService/Login',
  grpc.web.MethodType.UNARY,
  proto.grpc_service.LoginRequest,
  proto.grpc_service.LoginResponse,
  /**
   * @param {!proto.grpc_service.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc_service.LoginResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.grpc_service.LoginRequest,
 *   !proto.grpc_service.LoginResponse>}
 */
const methodInfo_InternalService_Login = new grpc.web.AbstractClientBase.MethodInfo(
  proto.grpc_service.LoginResponse,
  /**
   * @param {!proto.grpc_service.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc_service.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.grpc_service.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.grpc_service.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc_service.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc_service.InternalServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc_service.InternalService/Login',
      request,
      metadata || {},
      methodDescriptor_InternalService_Login,
      callback);
};


/**
 * @param {!proto.grpc_service.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc_service.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.grpc_service.InternalServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc_service.InternalService/Login',
      request,
      metadata || {},
      methodDescriptor_InternalService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc_service.CalendarRequest,
 *   !proto.grpc_service.CalendarResponse>}
 */
const methodDescriptor_InternalService_CalendarFeed = new grpc.web.MethodDescriptor(
  '/grpc_service.InternalService/CalendarFeed',
  grpc.web.MethodType.UNARY,
  proto.grpc_service.CalendarRequest,
  proto.grpc_service.CalendarResponse,
  /**
   * @param {!proto.grpc_service.CalendarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc_service.CalendarResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.grpc_service.CalendarRequest,
 *   !proto.grpc_service.CalendarResponse>}
 */
const methodInfo_InternalService_CalendarFeed = new grpc.web.AbstractClientBase.MethodInfo(
  proto.grpc_service.CalendarResponse,
  /**
   * @param {!proto.grpc_service.CalendarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc_service.CalendarResponse.deserializeBinary
);


/**
 * @param {!proto.grpc_service.CalendarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.grpc_service.CalendarResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc_service.CalendarResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc_service.InternalServiceClient.prototype.calendarFeed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc_service.InternalService/CalendarFeed',
      request,
      metadata || {},
      methodDescriptor_InternalService_CalendarFeed,
      callback);
};


/**
 * @param {!proto.grpc_service.CalendarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc_service.CalendarResponse>}
 *     Promise that resolves to the response
 */
proto.grpc_service.InternalServicePromiseClient.prototype.calendarFeed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc_service.InternalService/CalendarFeed',
      request,
      metadata || {},
      methodDescriptor_InternalService_CalendarFeed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc_service.FcmTokenRequest,
 *   !proto.grpc_service.FcmTokenResponse>}
 */
const methodDescriptor_InternalService_FcmTokenUpdate = new grpc.web.MethodDescriptor(
  '/grpc_service.InternalService/FcmTokenUpdate',
  grpc.web.MethodType.UNARY,
  proto.grpc_service.FcmTokenRequest,
  proto.grpc_service.FcmTokenResponse,
  /**
   * @param {!proto.grpc_service.FcmTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc_service.FcmTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.grpc_service.FcmTokenRequest,
 *   !proto.grpc_service.FcmTokenResponse>}
 */
const methodInfo_InternalService_FcmTokenUpdate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.grpc_service.FcmTokenResponse,
  /**
   * @param {!proto.grpc_service.FcmTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc_service.FcmTokenResponse.deserializeBinary
);


/**
 * @param {!proto.grpc_service.FcmTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.grpc_service.FcmTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc_service.FcmTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc_service.InternalServiceClient.prototype.fcmTokenUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc_service.InternalService/FcmTokenUpdate',
      request,
      metadata || {},
      methodDescriptor_InternalService_FcmTokenUpdate,
      callback);
};


/**
 * @param {!proto.grpc_service.FcmTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc_service.FcmTokenResponse>}
 *     Promise that resolves to the response
 */
proto.grpc_service.InternalServicePromiseClient.prototype.fcmTokenUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc_service.InternalService/FcmTokenUpdate',
      request,
      metadata || {},
      methodDescriptor_InternalService_FcmTokenUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc_service.EventInfoRequest,
 *   !proto.grpc_service.EventInfoResponse>}
 */
const methodDescriptor_InternalService_CalendarEventInfo = new grpc.web.MethodDescriptor(
  '/grpc_service.InternalService/CalendarEventInfo',
  grpc.web.MethodType.UNARY,
  proto.grpc_service.EventInfoRequest,
  proto.grpc_service.EventInfoResponse,
  /**
   * @param {!proto.grpc_service.EventInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc_service.EventInfoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.grpc_service.EventInfoRequest,
 *   !proto.grpc_service.EventInfoResponse>}
 */
const methodInfo_InternalService_CalendarEventInfo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.grpc_service.EventInfoResponse,
  /**
   * @param {!proto.grpc_service.EventInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc_service.EventInfoResponse.deserializeBinary
);


/**
 * @param {!proto.grpc_service.EventInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.grpc_service.EventInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc_service.EventInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc_service.InternalServiceClient.prototype.calendarEventInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc_service.InternalService/CalendarEventInfo',
      request,
      metadata || {},
      methodDescriptor_InternalService_CalendarEventInfo,
      callback);
};


/**
 * @param {!proto.grpc_service.EventInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc_service.EventInfoResponse>}
 *     Promise that resolves to the response
 */
proto.grpc_service.InternalServicePromiseClient.prototype.calendarEventInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc_service.InternalService/CalendarEventInfo',
      request,
      metadata || {},
      methodDescriptor_InternalService_CalendarEventInfo);
};


module.exports = proto.grpc_service;

