import Vue from 'vue';
import Router from 'vue-router';
import HelloWorld from '@/components/HelloWorld.vue';
import EventInfo from '@/components/EventInfo.vue';

// import Login from '../views/Login';
import 'bootstrap/dist/css/bootstrap.css';

Vue.use(Router);

const r = new Router({
  mode: "history",
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HelloWorld,
      meta: {
        requiresAuth2: true
      }
    },
    {
      path: '/info/:id',
      name: 'details',
      props: true,
      component: EventInfo,
      meta: {
        requiresAuth2: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: HelloWorld
    }
  ]
});

r.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("auth_token") == null) {
      next({
        "path": "/login"
      })
    } else {
      next()
    }
  } else {
    next();
  }
})


export default r