/* eslint-disable */
const { LoginRequest, LoginResponse, CalendarRequest, EventInfoRequest } = require("./grpc/app_pb");
const { InternalServiceClient } = require("./grpc/app_grpc_web_pb");

const URL = "https://api-mobile.advokatsariev.com";

class Api {

    constructor() {
        this.client = new InternalServiceClient(URL);
    }

    getMeta() {
        return {}
    }

    login() {
        let r = new LoginRequest();

        this.client.login(r, this.getMeta(), function (err, resp) {
            console.log(resp.toObject())
        })
    }
    getCalendarEvents(from, to) {
        return new Promise((resolve, reject) => {
            let r = new CalendarRequest()
            r.setFrom(from)
            r.setTo(to)
            this.client.calendarFeed(r, this.getMeta(), function (err, resp) {
                resolve(resp.toObject())
            })
        })
    }
    getEvent(ID) {
        return new Promise((resolve, reject) => {
            let r = new EventInfoRequest()
            r.setId(ID)
            this.client.calendarEventInfo(r, this.getMeta(), function (err, resp) {
                resolve(resp.toObject())
            })
        })
    }
}

export default new Api()