<template>
  <div id="calendar-container">
    <FullCalendar :options="calendarOptions" />
  </div>
</template>

<style >
#calendar-container {
  position: fixed;
  padding:5px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}
.fc .fc-col-header-cell-cushion { /* needs to be same precedence */
  padding-top: 5px; /* an override! */
  padding-bottom: 5px; /* an override! */
}
.fc-toolbar-title {
  display:none;
}
</style>

<script>

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import interactionPlugin from '@fullcalendar/interaction'
import bgLocale from '@fullcalendar/core/locales/bg';

import moment from 'moment';
moment.locale('bg')

import { EventBus } from '@/event-bus';

import API from "@/helpers/api";
/* eslint-disable */
var _ = require('lodash/core');

export default {
  methods: {
    loaded: function(){
      $('.fc-toolbar-title').on('DOMSubtreeModified', function(){
        this.nameChanged();
      });
    },
    nameChanged: function(){
      let newText = $('.fc-toolbar-title').text();
      try {
        window.ReactNativeWebView.postMessage(newText);
      } catch(er){
        // console.log(er)
      }
    }
  },
 
  created () {
    API.login();
    API.getCalendarEvents();
    EventBus.$on('redirect_info', function(el){
      this.$router.replace({ name: 'details', params: { id: el.id } })
    }.bind(this));
    /* eslint-disable */
    setTimeout(function(){
      $('.fc-toolbar-title').on('DOMSubtreeModified', function(){
          this.nameChanged();
      }.bind(this));
      this.nameChanged();
    }.bind(this),1000)
  },
  name: "HelloWorld",
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        locale: bgLocale,
        loading: function(){
          // console.log( $('.fc-toolbar-title').text() );
        },
        firstDay:1,
        plugins: [ dayGridPlugin, interactionPlugin,timeGridPlugin,listPlugin ],
        initialView: 'listWeek',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          // right: 'dayGridMonth,timeGridWeek,listWeek'
          right: 'dayGridMonth,listWeek'
        },
        eventClick: function(info) { 
          EventBus.$emit('redirect_info', info.event);
        },
        views: {
          listWeek: {
            eventContent: function(arg, createElement) { 
              // return arg
              var html 
              html = '<b>'+arg.event.title+'</b> <br /> ' + arg.event.extendedProps.description

              return { html: html }
            },
          }
        },
        
        height: '100%',
        businessHours:true,
        dateClick: this.handleDateClick,
        events: function(info,successCallback,failureCallback){
          // console.log(info)
          let st = moment(info.start)
          let end = moment(info.end)

          API.getCalendarEvents(st.format(),end.format()).then(function(resp){
              let r  = [];
              _.each(resp['eventsList'],function(el){
                let tmS = moment(el['timestart'])
                // let tmE = moment(el['timestart']).add(1,'h')

                r.push({
                  id: el['id'],
                  title: el['title'],
                  start: tmS.format(),
                  date: tmS.format('YYYY-MM-DD'),
                  description: el['description'],
                  backgroundColor: el['color'],
                  borderColor: el['color'],
                })
              })
              successCallback(r)
            });
        },
      }
    }
  }
};
</script>
